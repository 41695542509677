import React, { useContext, useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { IoChevronBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import LayoutComponent from "../components/shared/Layout";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import jwt_decode from "jwt-decode";
import { Controller, useForm } from "react-hook-form";
import { inRange, isEmpty, pick } from "lodash";
import { apiPost } from "../Utils/apiFetch";
import { preventMaxInput, validationRules } from "../Utils/constants";
import PhoneInput from "react-phone-input-2";
import { FaUser } from "react-icons/fa";
import "react-phone-input-2/lib/style.css";
import { useRef } from "react";
import { toast } from "react-toastify";
const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState({
    first: true,
    second: true,
    third: true,
  });
  const { logoutUser, user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const handleShow = (id) => {
    setShowPassword({ ...showPassword, [id]: !showPassword[id] });
  };

  const [success, setSuccess] = useState({
    check: true,
    message: "",
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    watch,
    trigger,
    setValue,
  } = useForm({});

  useEffect(() => {
    if (!isEmpty(watch("confirmPassword"))) {
      if (watch("newPassword")) {
        trigger("confirmPassword");
      }
    }
  }, [watch("newPassword")]);

  const onSubmit = async (body) => {
    const obj = {
      phone_number: body?.mobile,
      username: body?.username,
      otp: body?.verificationCode,
      pin: body?.newPassword,
    };
    const { status, data } = await apiPost(apiPath.forgotPassword, obj);
    if (status === 200) {
      if (data.success) {
        navigate("/");
        toast.success(data?.message)
      } 
    } else {
      toast.error(data?.message);
    }
  };
  const [otpSend, setOtpSend] = useState(false);
  const userSendOtp = async (value) => {
    const { status, data } = await apiPost(apiPath.sendOtp, {
      phone_number: value,
      username: watch("username"),
      type: "forgot",
    });
    if (status == 200) {
      if (data?.success) {
        setOtpSend(true);
        if (!isEmpty(data?.results?.otp)) {
          setValue("verificationCode", data?.results?.otp);
        }
      }
    }
  };
  return (
    <div>
      <LayoutComponent>
        <div className="p-3 change-layout-pass">
          <IoChevronBackCircle
            className="text-dark fs-1 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white p-sm-4 p-3 px-sm-5 px-4 change-password-form"
        >
          <label className="mb-4 d-block text-center fs-2 fw-bold">
            Forgot Password
          </label>
          <Form.Group className="mb-3">
            <FloatingLabel controlId="floatingPassword" label="UserName">
              <Form.Control
                type="text"
                autocomplete="off"
                className={errors.username ? "is-invalid" : ""}
                {...register("username", {
                  required: "Please enter Username",
                })}
                placeholder="Username"
              />{" "}
              <FaUser className="position-absolute translate-middle-y top-50" />
            </FloatingLabel>{" "}
            {errors.username && errors?.username?.message && (
              <label className="text-danger">{errors.username.message}</label>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <FloatingLabel controlId="floatingPassword" label="">
              <Controller
                className="form-group d-flex"
                control={control}
                name="mobile"
                rules={{
                  required: "Please enter mobile number.",
                  validate: (value) => {
                    let inputValue = value
                      ?.toString()
                      ?.slice(
                        inputRef?.current?.state?.selectedCountry?.countryCode
                          ?.length,
                        value?.length
                      );
                    if (inputValue?.length < 10) {
                      return "Mobile number must contain 10 digit";
                    } else if (inputValue?.length > 12) {
                      return "Mobile number should not exceed 12 digit";
                    } else {
                      return true;
                    }
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <PhoneInput
                    {...field}
                    inputExtraProps={{
                      ref,
                      required: true,
                      autoFocus: true,
                    }}
                    ref={inputRef}
                    inputStyle={{
                      width: "100%",
                      height: "38px",
                    }}
                    countryCodeEditable={false}
                    disableDropdown={true}
                    country={"in"}
                  />
                )}
              />{" "}
              <Button
                // disabled={otpSend}
                onClick={() => {
                  if (
                    !isEmpty(watch("mobile")) &&
                    !isEmpty(watch("username"))
                  ) {
                    let value = watch("mobile")
                      ?.toString()
                      ?.slice(
                        inputRef?.current?.state?.selectedCountry?.countryCode
                          ?.length,
                        watch("mobile")?.length
                      );
                    if (inRange(value?.length, 8, 12)) {
                      userSendOtp(watch("mobile"));
                    } else {
                      toast.error("Mobile number must contain 9 digit");
                    }
                  } else {
                    if (isEmpty(watch("mobile"))) {
                      toast.error("Please enter mobile number");
                    }
                    if (isEmpty(watch("username"))) {
                      toast.error("Please enter username");
                    }
                  }
                }}
                style={{ color: "white", marginRight: "5px" }}
                className="theme-btn py-1 px-3 fs-6 mt-2"
              >
                {otpSend ? "Resend OTP" : "Get OTP"}
              </Button>
            </FloatingLabel>
            {errors.mobile && errors?.mobile?.message && (
              <label className="text-danger">{errors.mobile.message}</label>
            )}
          </Form.Group>
          {otpSend && (
            <Form.Group className="mb-3">
              <FloatingLabel controlId="floatingPassword" label="Enter OTP">
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  maxlength="6"
                  className={errors.verificationCode ? " is-invalid " : ""}
                  {...register("verificationCode", {
                    required: "Please enter OTP",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid number",
                    },
                  })}
                />
                <FaLock className="position-absolute translate-middle-y top-50" />
              </FloatingLabel>
              {errors.verificationCode && errors?.verificationCode?.message && (
                <label className="text-danger">
                  {errors.verificationCode.message}
                </label>
              )}
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <FloatingLabel controlId="floatingPassword" label="New Password">
              <Form.Control
                type={!showPassword?.second ? "text" : "password"}
                placeholder="New Password"
                maxLength={16}
                minLength={8}
                onInput={(e) => preventMaxInput(e)}
                {...register("newPassword", {
                  required: "Please enter new password.",
                  validate: (value) => {
                    if (value === "") {
                      return true;
                    }
                    if (!!value.trim()) {
                      return true;
                    } else {
                      return "White spaces not allowed.";
                    }
                  },
                  pattern: {
                    value: validationRules.password,
                    message:
                      "New Password must contain lowercase,uppercase characters, numbers, special character and must be 8 character long.",
                  },
                })}
              />
              <FaLock className="position-absolute translate-middle-y top-50" />
              <Button
                className="position-absolute translate-middle-y top-50 end-0 me-3 bg-white border-0 text-dark p-0 "
                onClick={() => handleShow("second")}
              >
                {!showPassword?.second ? (
                  <FaEye className="opacity-100 fs-4" />
                ) : (
                  <FaEyeSlash className="opacity-100 fs-4" />
                )}
              </Button>
            </FloatingLabel>
            {errors.newPassword && errors?.newPassword?.message && (
              <label className="text-danger">
                {errors.newPassword.message}
              </label>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <FloatingLabel
              controlId="floatingPassword"
              label="Confirm Password"
            >
              <Form.Control
                type={!showPassword?.third ? "text" : "password"}
                placeholder="Confirm Password"
                onInput={(e) => preventMaxInput(e)}
                maxLength={16}
                minLength={8}
                {...register("confirmPassword", {
                  required: {
                    value: true,
                    message: "Please enter confirm password.",
                  },
                  validate: (value) => {
                    if (value === "") {
                      return true;
                    }
                    if (!isEmpty(watch("newPassword"))) {
                      if (value == watch("newPassword")) {
                        return true;
                      } else {
                        return "Password and confirm password does not match.";
                      }
                    }
                  },
                })}
                onChange={(e) => {
                  setValue("confirmPassword", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              />
              <FaLock className="position-absolute translate-middle-y top-50" />
              <Button
                className="position-absolute translate-middle-y top-50 end-0 me-3 bg-white border-0 text-dark p-0 "
                onClick={() => handleShow("third")}
              >
                {!showPassword?.third ? (
                  <FaEye className="opacity-100 fs-4" />
                ) : (
                  <FaEyeSlash className="opacity-100 fs-4" />
                )}
              </Button>
            </FloatingLabel>
            {errors.confirmPassword && errors?.confirmPassword?.message && (
              <label className="text-danger">
                {errors.confirmPassword.message}
              </label>
            )}
          </Form.Group>
          <div className="text-center mt-2">
            <Button
              type="submit"
              className="theme-btn ms-auto px-4"
              style={{ fontSize: "13px" }}
            >
              Change
            </Button>
          </div>
        </Form>
      </LayoutComponent>
    </div>
  );
};

export default ForgotPassword;
