import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Form, Button, FloatingLabel } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import LayoutComponent from "../components/shared/Layout";
import { IoIosArrowBack } from "react-icons/io";
import {
  FaUserAlt,
  FaLock,
  FaShieldAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import jwt_decode from "jwt-decode";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import { toast } from "react-toastify";
const Registration = () => {
  const { setUser,registerUser } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(true);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
  const handleShow = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      countryCode: 91,
      uniqueId: Math.random() * 10000,
    },
  });

  const { search } = useLocation();
  let code = search?.split("=")[1];
  let codeCondition = search?.split("=")?.includes("?referral_code")
    ? true
    : false;

  useEffect(() => {
    if (codeCondition) {
      setValue("refreralCode", code);
    }
  }, [code, codeCondition]);

  // const [isLoader, setLoader] = useState(false);
  const onSubmit = async (body) => {
    if (parseInt(body.validateCode) === parseInt(getValidateCode)) {
      registerUser({body,inputRef})
    }
  };

  const [getValidateCode, setValidateCode] = useState("");
  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130);
    setValidateCode(code);
  };
  useEffect(() => {
    changeCode();
  }, []);

  return (
    <>
      <div>
        <LayoutComponent>
          <div className="d-flex align-items-center justify-content-start">
            <div
              className="left-arrow-icon d-flex justify-content-center align-items-center"
              style={{
                background: "white",
                borderRadius: "50%",
                marginLeft: "8px",
                marginTop: "8px",
              }}
              onClick={() => navigate("/")}
            >
              <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
            </div>
          </div>

          <div className="login-log-sec w-100">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              className="bg-white p-4 overflow-y-auto"
            >
              <div className="login-logo">
                <img src="../assets/images/login-app.webp" alt="" />
              </div>
              <div className="d-flex justify-content-center mb-2">
                {" "}
                <h4>Registration</h4>
              </div>
            
              <Form.Group className="mb-4">
                <FloatingLabel controlId="floatingInput" label="Username *">
                  <Form.Control
                    {...register("username", {
                      required: "Please enter Username",
                    })}
                    autocomplete="off"
                    type="text"
                    placeholder="Username"
                  />
                  <FaUserAlt className="position-absolute translate-middle-y top-50" />
                </FloatingLabel>
                {errors.username && errors?.username?.message && (
                  <label className="text-danger">
                    {errors.username.message}
                  </label>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <FloatingLabel controlId="floatingInput" label="">
                  <Controller
                    control={control}
                    name="mobile"
                    rules={{
                      required: "Please enter mobile number.",
                      validate: (value) => {
                        let inputValue = value
                          ?.toString()
                          ?.slice(
                            inputRef?.current?.state?.selectedCountry
                              ?.countryCode?.length,
                            value?.length
                          );
                        if (inputValue?.length < 10) {
                          return "Mobile number must contain 10 digit";
                        } else if (inputValue?.length > 12) {
                          return "Mobile number should not exceed 12 digit";
                        } else {
                          return true;
                        }
                      },
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <>
                        <PhoneInput
                          {...field}
                          inputExtraProps={{
                            ref,
                            required: true,
                            autoFocus: true,
                          }}
                          ref={inputRef}
                          inputStyle={{
                            width: "100%",
                            height: "38px",
                          }}
                          countryCodeEditable={false}
                          disableDropdown={true}
                          country={"in"}
                        />
                      </>
                    )}
                  />
                </FloatingLabel>
                {errors?.mobile?.message && (
                  <div className="text-danger">{errors?.mobile?.message}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-4">
                <FloatingLabel controlId="floatingPassword" label="Password *">
                  <Form.Control
                    type={!showPassword ? "text" : "password"}
                    placeholder="Password"
                    maxLength={15}
                    {...register("password", {
                      required: "Please enter password",
                      minLength: {
                        value: 8,
                        message: "Password should contain atleast 8 characters",
                      },
                      maxLength: {
                        value: 16,
                        message:
                          "Password should contain maximum 16 characters",
                      },
                    })}
                  />
                  <FaLock className="position-absolute translate-middle-y top-50" />
                  <Button
                    className="position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                    onClick={() => handleShow()}
                  >
                    {!showPassword ? (
                      <FaEye className="opacity-100 fs-5" size="17" />
                    ) : (
                      <FaEyeSlash className="opacity-100 fs-5" size="17" />
                    )}
                  </Button>
                </FloatingLabel>
                {errors.password && errors?.password?.message && (
                  <label className="text-danger">
                    {errors.password.message}
                  </label>
                )}
              </Form.Group>

              <Form.Group className="mb-4">
                <FloatingLabel
                  controlId="floatingPassword"
                  label="Confirm Password *"
                >
                  <Form.Control
                    type={!showPasswordConfirm ? "text" : "password"}
                    placeholder="confirmPassword"
                    maxLength={15}
                    {...register("confirmPassword", {
                      required: "Please enter confirm password",
                      validate: (value) => {
                        if (!isEmpty(watch("password"))) {
                          if (value == watch("password")) {
                            return true;
                          } else {
                            return "password and confirm password does not match.";
                          }
                        }
                      },
                    })}
                  />
                  <FaLock className="position-absolute translate-middle-y top-50" />
                  <Button
                    className="position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                    onClick={() => handleShowConfirm()}
                  >
                    {!showPasswordConfirm ? (
                      <FaEye className="opacity-100 fs-5" size="17" />
                    ) : (
                      <FaEyeSlash className="opacity-100 fs-5" size="17" />
                    )}
                  </Button>
                </FloatingLabel>
                {errors.confirmPassword && errors?.confirmPassword?.message && (
                  <label className="text-danger">
                    {errors.confirmPassword.message}
                  </label>
                )}
              </Form.Group>

              <Form.Group className="mb-4">
                <FloatingLabel controlId="floatingValid" label="ValidationCode *">
                  <Form.Control
                    type="number"
                    keyboardType="numeric"
                    autocomplete="off"
                    maxLength="4"
                    {...register("validateCode", {
                      required: "Please enter validate code",
                      validate: {
                        validate: (value) =>
                          parseInt(value) === parseInt(getValidateCode) ||
                          "Invalid validate code",
                      },
                    })}
                    onChange={(e) => {
                      if (e.target.value.length == 4) {
                        e.target.blur();
                        unregister("validateCode", { keepValue: true });
                      }
                    }}
                    placeholder="ValidationCode"
                  />
                  <FaShieldAlt className="position-absolute translate-middle-y top-50" />
                  <canvas
                    ref={canvasRef}
                    onClick={changeCode}
                    className="position-absolute translate-middle-y top-50 canvas-img"
                    id="authenticateImage"
                  />
                </FloatingLabel>
                {errors.validateCode && errors?.validateCode?.message && (
                  <label className="text-danger">
                    {errors.validateCode.message}
                  </label>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <FloatingLabel controlId="floatingInput" label="Agent Code (Optional)">
                  <Form.Control
                    {...register("refreralCode", {
                      // required: "Please enter agent code",
                    })}
                    autocomplete="off"
                    type="text"
                    placeholder="Agent Code"
                  />
                  <FaUserAlt className="position-absolute translate-middle-y top-50" />
                </FloatingLabel>
                {/* {errors.refreralCode && errors?.refreralCode?.message && (
                  <label className="text-danger">
                    {errors.refreralCode.message}
                  </label>
                )} */}
              </Form.Group>
              <div className="text-center mt-3">
                <Button
                  style={{ fontSize: "15px" }}
                  type="submit"
                  className="theme-btn"
                >
                  Confirm
                </Button>
              </div>
            </Form>
          </div>
        </LayoutComponent>
      </div>
    </>
  );
};

export default Registration;
