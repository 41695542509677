import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  Container,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { isEmpty, startCase } from "lodash";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { apiPost } from "../Utils/apiFetch";
import { BsBank } from "react-icons/bs";
import apiPath from "../Utils/apiPath";
import { AmountArray } from "../Utils/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AuthContext from "../context/AuthContext";
import { CiBank } from "react-icons/ci";
import selectCheck from "../assets/images/select-check.svg";
import "./Deposit.css";
import LayoutComponent from "../components/shared/Layout";
import { toast } from "react-toastify";
import { FaRegCopy } from "react-icons/fa";
import Qr from "../assets/images/qr.png";
import UpiBg from "../assets/images/upi.png";
import { DepositInfo } from "../components/DepositInfo";
import PhonePe from "../assets/images/phonePe.png";
import GooglePay from "../assets/images/googlePay.png";
import PayTm from "../assets/images/payment.png";
import Bhim from "../assets/images/bhim.png";
import { IoIosArrowForward } from "react-icons/io";
import { IoQrCodeOutline } from "react-icons/io5";
const Deposit = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const [image, setImage] = useState("");
  const [bank, setBank] = useState([]);
  const { amounutRefresh } = useContext(AuthContext);
  const [isLoader, setLoader] = useState(false);
  const [detail, setDetail] = useState({});
  const [show, setShow] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const onSubmit = async (body) => {
    if (checkbox) {
      setLoader(true);
      let imageCheck = "";
      if (image !== "") {
        imageCheck = await uploadImage(image);
      }
      body = {
        amount: body?.amount,
        TransactionId: body?.transactionId,
        TransactionFile: image !== "" ? imageCheck : "",
        bank: select,
        AccountName: detail?.account_name || "",
        BankAccount: detail?.bank_name || "",
        transactionType: "deposit",
        bonus: body?.bonus,
        offer: offer == "first" ? "offer1" : "offer2",
      };
      const { status, data } = await apiPost(apiPath.depositAmount, body);
      if (status == 200) {
        if (data?.success) {
          toast.success(data?.message);
          amounutRefresh();
          setImage("");
          setCheckBox(false);
          reset();
          setLoader(false);
        } else {
          toast.error(data?.message);
          setLoader(false);
        }
      } else {
        toast.error(data?.message);
        setLoader(false);
      }
    } else {
      toast.error("Please Select Terms and Condition");
    }
  };

  const getBank = async () => {
    const { status, data } = await apiPost(apiPath.getBank, {
      type: "deposit",
    });
    if (status == 200) {
      if (data?.success) {
        setBank(data?.results?.bankList);
        setDetail(data?.results?.bankList[0]);
        setSelect(data?.results?.bankList[0]?._id);
      }
    }
  };

  const uploadImage = async (obj) => {
    let form = new FormData();
    form.append("TransactionFile", obj);
    const { status, data } = await apiPost(apiPath.reciptUpload, form);
    if (status == 200) {
      return data?.path;
    } else {
      return false;
    }
  };
  useEffect(() => {
    getBank();
  }, []);
  const [priceValue, setPriceValue] = useState("");
  const priceCalculate = async (price, value) => {
    setPriceValue(price);
    setValue("amount", Number(price) + Number(value));
  };

  const resetPrice = async () => {
    setValue("amount", "");
  };
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [isCopied]);

  const [select, setSelect] = useState("bankTransfer");
  const [offer, setOffer] = useState("first");
  const [showMore, setShowMore] = useState("");
  return (
    <LayoutComponent visibilityType={true} newType={true}>
      <Container>
        <div
          style={{ marginTop: "70px" }}
          class="main dw-p deposit-box page-content-box w-100 bg-gradual-black"
        >
          <div className="width70">
            <div class="usrTrans-wrapper common-box form-f mb-66">
              <div class="withdraw-form usrTrans-form">
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  id="deposit_form"
                  class="deposit_form"
                >
                  <div
                    style={{
                      height: showMore ? "auto" : "190px",
                      overflowY: "hidden",
                    }}
                    class="member-menu-box member-list select-group checkbox-style position-relative"
                  >
                    <div class="title">
                      <h2>
                        <span>Payment Options</span>
                      </h2>
                    </div>
                    <div className="upi-column-outer ">
                      {bank?.map((res) => {
                        return (
                          <div className="position-relative upi-column mb-2">
                            <input
                              className="position-absolute"
                              checked={select === res?._id ? true : false}
                              type="radio"
                              onChange={() => {
                                setSelect(res?._id);
                                setDetail(res);
                              }}
                              style={{
                                width: "15px",
                                height: "15px",
                                opacity: "1",
                                top: "8%",
                                right: "10%",
                              }}
                            />
                            <div
                              className="d-flex flex-column align-items-start"
                              style={{ width: "90%" }}
                            >
                              {res?.depositType == "bankTransfer" && (
                                <CiBank size={23} />
                              )}{" "}
                              {res?.depositType == "qr" && (
                                <IoQrCodeOutline size={23} />
                              )}
                              {res?.depositType == "upi" && (
                                <img width={"50%"} src={UpiBg} />
                              )}
                              <span
                                style={{ fontWeight: "600", fontSize: "10px" }}
                              >
                                {res?.account_name}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div style={{ fontSize: "12px" }} className="mt-1">
                      <p className="p-0 m-0">
                        1. Deposit money only in the below available accounts to
                        get the fastest credits and avoid possible delays.
                      </p>
                      {!showMore && (
                        <p
                          onClick={() => setShowMore(true)}
                          style={{ fontWeight: "600" }}
                          className="text-danger"
                        >
                          Show More...
                        </p>
                      )}
                      <div>
                        <p className="p-0 m-0">
                          2. Deposits made 45 minutes after the account removal
                          from the site are valid &amp; will be added to their
                          wallets.
                        </p>
                        <p className="p-0 m-0">
                          3. Site is not responsible for money deposited to Old,
                          Inactive or Closed accounts.
                        </p>
                        <p className="p-0 m-0">
                          4. After deposit, add your UTR and amount to receive
                          balance.
                        </p>
                        <p className="p-0 m-0">
                          5. NEFT receiving time varies from 40 minutes to 2
                          hours.
                        </p>
                      </div>
                      {showMore && (
                        <p
                          onClick={() => setShowMore(false)}
                          style={{ fontWeight: "600" }}
                          className="text-danger pb-0 mb-0"
                        >
                          Show Less...
                        </p>
                      )}
                    </div>
                  </div>
                  <div class="member-menu-box member-list select-group checkbox-style ">
                    <div class="title mb-1">
                      <h2>
                        <span>Payment Detail</span>
                      </h2>
                    </div>
                    {detail?.depositType !== "bankTransfer" && (
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        {detail?.depositType == "qr" && (
                          <img
                            width={"70%"}
                            className="mb-2"
                            src={`${process.env.REACT_APP_API_BASE_URL}${detail?.qr_path}`}
                          />
                        )}
                        {detail?.depositType !== "qr" && (
                          <div className="w-100 d-flex justify-content-between align-items-center mb-1">
                            <div
                              style={{ fontSize: "14px" }}
                              className="d-flex flex-column align-items-start"
                            >
                              <span>UPI ID</span>{" "}
                              <span className="span-deposit">
                                {detail?.upiId || "-"}
                              </span>
                            </div>{" "}
                            <CopyToClipboard
                              onCopy={() => toast.success("Copied!")}
                              text={detail?.upiId || "-"}
                            >
                              <FaRegCopy />
                            </CopyToClipboard>
                          </div>
                        )}
                        <div className="w-100 d-flex justify-content-between align-items-center">
                          <div
                            style={{ fontSize: "14px" }}
                            className="d-flex flex-column align-items-start"
                          >
                            <span>Account Name</span>{" "}
                            <span className="span-deposit">
                              {detail?.account_name || "-"}
                            </span>
                          </div>{" "}
                          <CopyToClipboard
                            onCopy={() => toast.success("Copied!")}
                            text={detail?.account_name || "-"}
                          >
                            <FaRegCopy />
                          </CopyToClipboard>
                        </div>
                      </div>
                    )}
                    {detail?.depositType == "bankTransfer" && (
                      <div>
                        <div
                          style={{ fontSize: "14px" }}
                          className="d-flex justify-content-between align-items-center mb-1"
                        >
                          <div>
                            <span>Account : </span>{" "}
                            <span className="span-deposit">
                              {detail?.account_number || "-"}
                            </span>
                          </div>
                          <CopyToClipboard
                            onCopy={() => toast.success("Copied!")}
                            text={detail?.account_number || "-"}
                          >
                            <FaRegCopy />
                          </CopyToClipboard>
                        </div>{" "}
                        <div
                          style={{ fontSize: "14px" }}
                          className="d-flex justify-content-between align-items-center mb-1"
                        >
                          <div>
                            <span>IFSC : </span>{" "}
                            <span className="span-deposit">
                              {detail?.ifsc_code || "-"}
                            </span>
                          </div>
                          <CopyToClipboard
                            onCopy={() => toast.success("Copied!")}
                            text={detail?.ifsc_code || "-"}
                          >
                            <FaRegCopy />
                          </CopyToClipboard>
                        </div>{" "}
                        <div
                          style={{ fontSize: "14px" }}
                          className="d-flex justify-content-between align-items-center mb-1"
                        >
                          <div>
                            <span>Account Name : </span>{" "}
                            <span className="span-deposit">
                              {detail?.account_name || "-"}
                            </span>
                          </div>
                          <CopyToClipboard
                            onCopy={() => toast.success("Copied!")}
                            text={detail?.account_name || "-"}
                          >
                            <FaRegCopy />
                          </CopyToClipboard>
                        </div>{" "}
                        <div
                          style={{ fontSize: "14px" }}
                          className="d-flex justify-content-between align-items-center mb-1"
                        >
                          <div>
                            <span>Bank Name : </span>{" "}
                            <span className="span-deposit">
                              {detail?.bank_name || "-"}
                            </span>
                          </div>
                          <CopyToClipboard
                            onCopy={() => toast.success("Copied!")}
                            text={detail?.bank_name || "-"}
                          >
                            <FaRegCopy />
                          </CopyToClipboard>
                        </div>
                      </div>
                    )}
                  </div>
                  {!isEmpty(watch("bankAccount")) && (
                    <>
                      <div class="usrTrans-seperate bankInfoField bankInfo">
                        <div class="transaction-title">
                          <span>{"Account Name"}</span>
                          <span class="copyBtn bg-gradient-secondary">
                            <i class="fas fa-copy"></i>
                          </span>
                        </div>
                        <div class="transaction-option m-auto">
                          <input
                            {...register("accountName")}
                            class="text-input"
                            id="depositAccName"
                            disabled
                          />
                        </div>
                      </div>

                      <div
                        class="usrTrans-seperate bankInfoField bankInfo "
                        style={{ position: "relative", width: `100%` }}
                      >
                        <div class="transaction-title">
                          <span>{"Account Number / UPI"}</span>
                          <span class="copyBtn bg-gradient-secondary">
                            <i class="fas fa-copy"></i>
                          </span>
                        </div>
                        <div class="transaction-option m-auto">
                          <input
                            {...register("bankAccount")}
                            disabled
                            class="text-input"
                            id="depositAccNo"
                          />
                          <div
                            className="input-group-btn"
                            style={{
                              position: "absolute",
                              right: "0",
                              top: "-10",
                              bottom: "0px",
                              filter: `invert(1)`,
                            }}
                          >
                            <span
                              className="btn btn-outline-primary"
                              style={{
                                backgroundColor: "transparent",
                                border: "0",
                                padding: "8px",
                                paddingTop: "0px",
                                height: "72%",
                              }}
                            >
                              <CopyToClipboard
                                text={getValues("bankAccount")}
                                onCopy={() => setCopied(true)}
                              >
                                <Button
                                  className="copy-btn"
                                  style={{
                                    fontSize: `13px`,
                                    padding: `5px`,
                                    backgroundColor: `#005dac`,
                                    color: `#fff`,
                                  }}
                                >
                                  {isCopied ? "Copied!" : "Copy"}
                                </Button>
                              </CopyToClipboard>
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {detail?.depositType !== "bankTransfer" && (
                    <div class="member-menu-box member-list select-group checkbox-style ">
                      <div class="title mb-3">
                        <h2 className="mb-0">
                          <span>{"Pay Using"}</span>{" "}
                          <span class="important-icon">*</span>
                        </h2>
                      </div>
                      <div className="inner-deposit">
                        <div>
                          <img src={PhonePe} />
                          <span>Phone Pe</span>
                        </div>{" "}
                        <div>
                          <img src={GooglePay} />
                          <span>Google Pay</span>
                        </div>{" "}
                        <div>
                          <img src={PayTm} />
                          <span>Payment</span>
                        </div>{" "}
                        <div>
                          <img src={Bhim} />
                          <span>BHIM</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div class="member-menu-box member-list select-group checkbox-style ">
                    <div class="title mb-0">
                      <h2 className="mb-0">
                        <span>{"Amount"}</span>{" "}
                        <span class="important-icon">*</span>
                        <i>300.00 - 50,000.00</i>
                      </h2>
                    </div>
                    <div class="deposit-amount">
                      <div class="transaction-option m-auto">
                        <input
                          style={{
                            borderRadius: "0",
                            border: "1px solid black",
                          }}
                          {...register("amount", {
                            required: {
                              message: "Please enter amount",
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Please enter valid number",
                            },
                            validate: (value) => {
                              if (value < 300) {
                                return "Min Deposit amount should be 300";
                              } else if (value > 50000) {
                                return "Max Deposit amount should be below 50,000";
                              } else {
                                return true;
                              }
                            },
                          })}
                          type="text"
                          class="text-input"
                          id="depositAmt"
                          placeholder="0.00"
                          onClick={() => resetPrice()}
                        />
                      </div>
                      {errors?.amount?.message && (
                        <div class="transaction-errMsg text-danger depositMsg mb-1">
                          {errors?.amount?.message}
                        </div>
                      )}
                    </div>
                    <div class="active">
                      <ul class="col4">
                        {AmountArray?.map((item, key) => {
                          return (
                            <li
                              onChange={() =>
                                priceCalculate(item?.amount, watch("amount"))
                              }
                            >
                              <input type="radio" name="depositAmount" />
                              <label>
                                <span> {"+" + item?.amount} </span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {/* <div class="input-group money">
                      <div class="input-wrap ">
                        <input
                          type="text"
                          class=" ng-untouched ng-pristine ng-valid"
                          placeholder="0.00"
                          disabled=""
                        />
                        <a class="delete-btn red"></a>
                      </div>
                    </div> */}
                  </div>

                  <div class="usrTrans-seperate default-type">
                    <div
                      class="transaction-title"
                      style={{ marginBottom: "10px" }}
                    >
                      <span>{"Upload your payment slip below *"}</span>
                    </div>
                    <div class="transaction-option m-auto">
                      <div class="fileupload-box bg-gradient-secondary">
                        <span style={{ fontSize: "11px" }}>
                          {"Upload or drop a file right here"}
                        </span>
                        <input
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                          }}
                          type="file"
                          class="fileInput"
                          accept="image/png, image/jiffy, image/jpeg, image/jpg"
                          id="depositReceipt"
                          name="depositReceipt"
                        />
                      </div>
                      {image !== "" && (
                        <button
                          onClick={() => setImage("")}
                          class="btn-remove removeReceipt bg-gradient-third"
                        >
                          {"Remove Image"}
                        </button>
                      )}
                    </div>
                    {errors?.image?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.image?.message}
                      </div>
                    )}
                  </div>

                  <div class="usrTrans-seperate default-type">
                    <div class="transaction-title">
                      <span>{"Unique Transaction Refrence *"}</span>
                    </div>
                    <div class="transaction-option m-auto">
                      <input
                        {...register("transactionId", {
                          required: {
                            value: true,
                            message: "Please enter Transaction ID",
                          },
                        })}
                        style={{ borderRadius: "0", border: "1px solid black" }}
                        placeholder="UTR / RRN Number"
                        class="text-input"
                        id="depositReferId"
                      />
                    </div>
                    {errors?.transactionId?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.transactionId?.message}
                      </div>
                    )}
                  </div>

                  <div class="usrTrans-seperate default-type">
                    <div class="transaction-title mb-2">
                      <span>{"Available Offers *"}</span>
                    </div>
                    <div
                      onClick={() => setOffer("first")}
                      className={`offer-tab mb-2 ${
                        offer == "first" && "offer-tab-active"
                      }`}
                    >
                      <div>
                        <p style={{ color: "grey", fontSize: "14px" }}>
                          Get 10% bonus on FIRST Deposit
                        </p>{" "}
                        <p style={{ fontSize: "13px" }}>FIRST Depost Bonus</p>{" "}
                        <p
                          onClick={() => setShow(true)}
                          style={{ textDecoration: "underline" }}
                        >
                          ( Terms and Condition )
                        </p>
                      </div>
                    </div>
                    <div
                      onClick={() => setOffer("second")}
                      className={`offer-tab ${
                        offer == "second" && "offer-tab-active"
                      }`}
                    >
                      <div>
                        <p style={{ color: "grey", fontSize: "14px" }}>
                          Get 5% bonus on every Deposit
                        </p>{" "}
                        <p style={{ fontSize: "13px" }}>Every Depost Bonus</p>{" "}
                        <p
                          onClick={() => setShow(true)}
                          style={{ textDecoration: "underline" }}
                        >
                          ( Terms and Condition )
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex w-100 justify-content-start mt-2 p-2">
                    <input
                      checked={checkbox}
                      onClick={(e) => setCheckBox(e.target.checked)}
                      type="checkbox"
                      style={{ marginRight: "10px", width: "22px" }}
                    />
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      I have read and agree with&nbsp;
                      <span>the terms of payment and withdrawal policy.</span>
                    </span>
                  </div>

                  <div class="transaction-option p-2">
                    <div class="transaction-btn">
                      <button
                        type="submit"
                        disabled={isLoader}
                        className="btn-submit bg-gradient-primary text-capitalize"
                      >
                        {isLoader ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <DepositInfo show={show} onHide={() => setShow(false)} />
    </LayoutComponent>
  );
};

export default Deposit;
