import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import banner1 from "../assets/images/Banner 1.webp";
import LayoutComponent from "../components/shared/Layout";
import { get, isEmpty } from "lodash";
import BeforeLogin from "./BeforeLogin";
import CurrentNews from "../components/CurrentNews";
import HomeBanner from "../components/HomeBanner";
import { useNavigate } from "react-router-dom";
import BetSlipContext from "../context/BetSlipContext";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { FaUpload } from "react-icons/fa";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import Whatsapp from "../assets/images/whatapp.svg";
import { IoLogoWhatsapp } from "react-icons/io";
const HomeNew = () => {
  const { messagelist, setAnnouncement, user, setGameTab, setActive, banner } =
    useContext(AuthContext);
  let { dolaunchCasinoNew } = useContext(BetSlipContext);
  const navigate = useNavigate();

  return (
    <div>
      <LayoutComponent visibilityType={true}>
        {messagelist?.length > 0 && (
          <CurrentNews
            message={messagelist}
            setAnnouncement={setAnnouncement}
          />
        )}{" "}
        {isEmpty(user) && (
          <>
            <HomeBanner messagelist={messagelist} banner={banner} />
          </>
        )}
        {isEmpty(user) ? (
          <BeforeLogin />
        ) : (
          <div className="position-relative">
            <div>
              <HomeBanner messagelist={messagelist} banner={banner} />
            </div>
            <div className="p-2">
              <div
                style={{
                  background: "linear-gradient(90deg,#fff 50%,#d4b94e)",
                  borderRadius: "7px",
                }}
                className=" d-flex justify-content-evenly align-items-center pt-2 pb-2"
              >
                <Button
                  onClick={() => navigate("/withdraw")}
                  style={{
                    width: "45%",
                    background: "#0f2327",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  className="btn btn-primary d-flex justify-content-center align-items-center"
                >
                  <FaDownload style={{ marginRight: "10px" }} /> Withdraw
                </Button>
                <Button
                  onClick={() => navigate("/deposit")}
                  style={{
                    width: "45%",
                    background: "linear-gradient(#022c43, #18b0c8)",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  className="btn btn-primary d-flex justify-content-center align-items-center"
                >
                  <FaUpload style={{ marginRight: "10px" }} /> Deposit
                </Button>{" "}
              </div>
            </div>
            <div className="homenew-outer mt-1">
              <div
                style={{ position: "relative" }}
                onClick={() => {
                  navigate("/sports");
                  setActive("Cricket");
                  setGameTab("cricket");
                }}
              >
                <img src={"../assets/images/s3.png"} />
                <p className="entrance-title">Cricket</p>
              </div>
              <div
                style={{ position: "relative" }}
                onClick={() => {
                  navigate("/sports");
                  setActive("Tennis");
                  setGameTab("tennis");
                }}
              >
                <img src={"../assets/images/s5.png"} />
                <p className="entrance-title">Tennis</p>
              </div>

              <div
                style={{ position: "relative" }}
                onClick={() => {
                  navigate("/sports");
                  setActive("Soccer");
                  setGameTab("soccer");
                }}
              >
                <img src={"../assets/images/s4.png"} />
                <p className="entrance-title">Soccer</p>
              </div>
              {gameArray?.map((res) => {
                return (
                  <div
                    style={{ position: "relative" }}
                    onClick={() =>
                      dolaunchCasinoNew(
                        res?.game == "poker"
                          ? {
                              gameCode: res?.gameCode,
                              legacy: res?.legacy,
                              type: res?.type,
                            }
                          : {
                              gameCode: res?.gameCode,
                              legacy: res?.legacy,
                              type: res?.type,
                            }
                      )
                    }
                  >
                    <img src={res?.image} />
                    <p className="entrance-title">{res?.title}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {isEmpty(user) && (
          <img
            src={Whatsapp}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=917301476666&text="
              )
            }
            style={{ position: "fixed", bottom: "5%", left: "5%" }}
          />
        )}
      </LayoutComponent>
    </div>
  );
};

export default HomeNew;

const gameArray = [
  {
    image: "../assets/images/poker/evoluation7.webp",
    title: "Evolution Lobby",
    gameCode: "21000",
    type: "sec",
  },
  {
    gameCode: 101313,
    image: "../assets/images/evolution-new.webp",
    id: "",
    title: "Ezugi Lobby",
    type: "sec",
  },

  {
    image: "../assets/images/Aura/auraLobby.webp",
    title: "Aura Lobby",
    gameCode: "98789",
    game: "poker",
    type: "first",
    legacy: "",
  },
  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    game: "poker",
    type: "first",
    legacy: "98789_2",
  },

  {
    image: "../assets/images/poker/live1.webp",
    gameCode: "77409",
    title: "Plinkxo",
    type: "first",
  },
  {
    image: "../assets/images/poker/live2.webp",
    gameCode: "5268",
    title: "Cricket Live",
    type: "first",
  },

  {
    image: "../assets/images/poker/avaitrix.jpg",
    gameCode: "9999",
    title: "Aviatrix",
    legacy: "67722-1_8",
    type: "first",
  },
  {
    image: "../assets/images/poker/aviator1.webp",
    gameCode: "9999",
    title: "Aviator",
    legacy: "67722-2_8",
    type: "first",
  },

  {
    image: "../assets/images/poker/DICE-7.webp",
    title: "Sicbo",
    gameCode: "21464",
    id: "RngSicbo00000001",
    type: "sec",
  },
  {
    image: "../assets/images/poker/ROULETTE-19.webp",
    title: "Football Rouleete",
    gameCode: "21000",
    id: "frenchroulettene",
    type: "sec",
  },

  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
    game: "poker",
    type: "first",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
    game: "poker",
    type: "first",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
    game: "poker",
    type: "first",
  },

  {
    image: "../assets/images/poker/PT-LIVE-017.webp",
    title: "Casino Hold'em",
    gameCode: "21000",
    id: "casinoholdemne00",
    type: "sec",
  },
  {
    image: "../assets/images/poker/BACCARAT-7.webp",
    title: "Baccarat",
    gameCode: "21000",
    id: "baccarat2ne00000",
    type: "sec",
  },
  {
    image: "../assets/images/poker/EVOLUTION-LIVE-204.png",
    title: "Andhar Bahar",
    gameCode: "21296",
    type: "sec",
    id: "AndarBahar000001",
  },
  {
    image: "../assets/images/poker/BACCARAT-5.webp",
    title: "Lightning Baccarat",
    type: "sec",
    gameCode: "21280",
    id: "rng-lbaccarat000",
  },
  {
    image: "../assets/images/poker/ROULETTE-12.webp",
    title: "Speed Roulette",
    gameCode: "21257",
    type: "sec",
    id: "SpeedAutoRo00001",
  },
  {
    image: "../assets/images/poker/GAME-SHOW-2.webp",
    title: "Cash or Crash",
    gameCode: "21254",
    type: "sec",
    id: "CashOrCrash00001",
  },
  {
    image: "../assets/images/poker/GAME-SHOW-14.webp",
    title: "Mega Ball",
    gameCode: "21000",
    type: "sec",
    id: "MegaBall00000001",
  },
  {
    image: "../assets/images/poker/ROULETTE-11.webp",
    title: "Auto Roulette",
    gameCode: "21190",
    type: "sec",
    id: "01rb77cq1gtenhmo",
  },
  {
    image: "../assets/images/poker/ROULETTE-18.webp",
    title: "Roulette",
    gameCode: "21027",
    type: "sec",
    id: "wzg6kdkad1oe7m5k",
  },
  {
    image: "../assets/images/poker/EVOLUTION-LIVE-009.webp",
    title: "Triple Card",
    type: "sec",
    gameCode: "21000",
    id: "n5emwq5c5dwepwam",
  },

  {
    image: "../assets/images/poker/vivo5.webp",
    gameCode: "75695",
    type: "sec",
    title: "Quenco Baccarat",
  },
  {
    image: "../assets/images/poker/vivo6.webp",
    gameCode: "82701",
    type: "sec",
    title: "Live Baccarat",
  },
  {
    image: "../assets/images/poker/vivo7.webp",
    gameCode: "82690",
    type: "sec",
    title: "Summaca Baccarat",
  },
  {
    image: "../assets/images/poker/vivo8.webp",
    gameCode: "82703",
    type: "sec",
    title: "Blackjack",
  },
];
