import React from "react";
import { Modal } from "react-bootstrap";

export const DepositInfo = ({ show, onHide }) => {
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Body style={{height:"400px",overflowY:"scroll"}}>
        <div className="deposit-info">
          <p>
            Bonus amount: 10% of FIRST Deposit up to ₹2,400
            <br />
            Min deposit:&nbsp;₹100
            <br />
            Expiry:&nbsp;7 days
            <br />
            Max Bonus withdrawal:&nbsp;Up to ₹2,500
            <br />
            turnover conditions:&nbsp;The deposit amount (main wallet) must be
            wagered 10 (TEN) times with odds&nbsp;from 1.40 to 3.5&nbsp;in order
            to get the bonus amount in the main wallet
            <br />
            Eligible markets:&nbsp;Only sportsbook and match odds markets are
            allowed
          </p>
          <p>Terms and Conditions</p>
          <p>
            1. Grab a 10% FIRST Deposit Bonus up to ₹2,500&nbsp;
            <br />
            2. Only one bonus can be active at a time. All the other bonuses
            will be waiting in the queue.
            <br />
            3. The bonus will be automatically credited to your account once the
            qualifying deposit is successfully made.
            <br />
            <br />
          </p>
          <ol>
            <li>
              <p>
                Eligibility
                <br />
                1. The deposit bonus is available to all customers registered on
                LTS247.coM&nbsp;
                <br />
                2. The bonus amount is 10% of every deposit, up to a maximum of
                ₹2,500 provided the wagering requirements are met.
                <br />
                3. Only one bonus is allowed per customer, per family, per
                address, per shared computer and shared IP address, and per
                account details including email address, bank account details,
                credit card information, and payment system account number.
                <br />
                4. The offer is only available to customers with one user
                account.&nbsp;
                <br />
                <br />
              </p>
            </li>
          </ol>
          <ol start="2">
            <li>
              <p>
                Wagering requirements
                <br />
                1. Before any winnings from the bonus can be withdrawn, you must
                meet the wagering requirements mentioned in the bonus widget.
                <br />
                2. Before any bonus funds or associated winnings can be
                withdrawn, you must wager the bonus amount 10 (TEN) times in
                eligible bets (only Sportsbook &amp; Match odds).
                <br />
                3. Only bets with odds of 1.40 to 3.50 will contribute towards
                meeting the wagering requirements.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                Time limit
                <br />
                1. The bonus will be activated immediately after a deposit is
                made.
                <br />
                2. You must fulfill the wagering requirements within 7 days from
                the date of activation. Failure to do so may result in the bonus
                and related winnings being forfeited/lost.
                <br />
                3. Once wagered, the remaining (max ₹2,500) bonus amount will be
                transferred to the customer's Main wallet within 2 days.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                Bonus Winnings:&nbsp;
                <br />
                1. The bonus amount will be credited to your main wallet once
                the wagering requirements are completed.&nbsp;
                <br />
                2. Withdrawals from the customer account are unavailable until
                the wagering requirements have been met.
                <br />
                3. Instant withdrawals when wagering is in progress might lead
                to the cancellation of the bonus.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                Bonus Abuse
                <br />
                1. If the company suspects a customer of misuse of the bonus,
                foul play, and/or participation in strategies that the company
                in its sole discretion deems to be abusive, the Company reserves
                the right to apply special wagering requirements to such
                customers, including bonus cancellation, without any
                explanation.&nbsp;
                <br />
                2. Misusing this bonus offer will lead to the cancelation of the
                bonus and all bonus winnings, or to the closure of the account.
                <br />
                3. even odd and over under markets of cricket and football from
                premium and sportsbook is not allowed for wagering
              </p>
            </li>
          </ol>
          <ol start="6">
            <li>
              <p>
                General Terms
                <br />
                1. The company has the right to amend the terms of the offer,
                cancel or renew the request, or refuse to allow participation at
                any time without prior notice.
                <br />
                2. The bonus is deemed to have been wagered only after all the
                bets for the specified amount have been settled.
                <br />
                3. Players found to have multiple accounts to abuse the bonus
                system may have their bonuses and winnings voided.
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </p>
            </li>
          </ol>
          <p>
            By claiming the 10% FISRT deposit bonus up to $2,500, you
            acknowledge that you have read, understood, and agreed to these
            terms and conditions. In case of any dispute, our decision will be
            final and binding.
            <br />
            सर्वोत्तम ग्राहक,
          </p>
          <p>
            10% तक ₹2,500 तत्काल नि: शुल्क बोनस पाएं
            <br />
            बोनस राशि: जमा का 10% ₹2,500 तक
            <br />
            न्यूनतम जमा: ₹100
            <br />
            समाप्ति: 7 दिन
            <br />
            अधिकतम बोनस वापसी: ₹2,500 तक
          </p>
          <p>
            टर्नओवर शर्तें: बोनस राशि (मुख्य वॉलेट) का जमा राशि से 1.40 से 3.5
            तक के योग सहित 10 (TEN) बार दांव करना होगा, ताकि मुख्य वॉलेट में
            बोनस राशि मिल सके।
          </p>
          <p>
            योग्य बाजार: केवल स्पोर्ट्सबुक और मैच ओड्स बाजारें अनुमति दी जाती
            हैं।
          </p>
          <p>शर्तें और नियम</p>
          <p>
            10% जमा बोनस उपलब्ध कराएं तक ₹2,500 तक
            <br />
            एक समय में केवल एक बोनस सक्रिय हो सकता है। सभी अन्य बोनस प्रतीक्षा
            में होंगे।
            <br />
            योग्य जमा सफलतापूर्वक किए जाने पर बोनस खुद विलय हो जाएगा।
          </p>
          <p>योग्यता</p>
          <p>
            जमा बोनस LTS247.coM पर पंजीकृत सभी ग्राहकों के लिए उपलब्ध है।
            <br />
            बोनस राशि हर जमा का 10%, अधिकतम ₹2,500 तक है यदि जुआ जाने वाली
            शर्तें पूरी की जाती हैं।
            <br />
            प्रत्येक ग्राहक, प्रत्येक परिवार, प्रत्येक पता, प्रत्येक साझा
            कंप्यूटर और साझा आईपी पते, और प्रत्येक खाता विवरण, इमेल पता, बैंक
            खाता विवरण, क्रेडिट कार्ड जानकारी, और भुगतान प्रणाली खाता संख्या के
            लिए केवल एक बोनस अनुमति दी जाएगी।
            <br />
            यह प्रस्ताव केवल एक उपयोगकर्ता खाते वाले ग्राहकों के लिए है।
          </p>
          <p>जुआ शर्तें</p>
          <p>
            बोनस से किए गए किसी भी जीत को निकासी करने से पहले, आपको बोनस विजेट
            में उल्लेखित जुआ शर्तों को पूरा करना होगा।
            <br />
            किसी भी बोनस राशि या संबंधित जीत को निकासी करने से पहले, आपको योग्य
            शर्तों में 10 (TEN) बार बोनस राशि का दांव लगाना होगा (केवल
            स्पोर्ट्सबुक और मैच ओड्स ही)।
            <br />
            योग्य दांव शर्तों को पूरा करने के लिए 1.40 से 3.50 तक के योगयता वाले
            दांव किए जाने आवश्यक हैं।
          </p>
          <p>समय सीमा:</p>
          <p>
            बोनस जमा करने के तुरंत बाद यह सक्रिय हो जाएगा।
            <br />
            आपको जुआ शर्तों को 7 दिनों के अंदर पूरा करना होगा। यदि ऐसा नहीं होता
            है, तो बोनस और संबंधित जीत को हानि हो सकती है।
            <br />
            जब शर्तों को पूरा कर लिया जाता है, शेष (अधिकतम ₹2,500) बोनस राशि को
            ग्राहक के मुख्य वॉलेट में 2 दिनों के भीतर स्थानांतरित किया जाएगा।
          </p>
          <p>बोनस विजय:</p>
          <p>
            बोनस राशि जुआ शर्तों को पूरा करने पर आपके मुख्य वॉलेट में क्रेडिट हो
            जाएगी।
            <br />
            जुआ शर्तों को पूरा करने तक ग्राहक खाते से निकासी उपलब्ध नहीं होती
            है।
            <br />
            शर्तों के प्रगति के दौरान तत्काल निकासी बोनस के रद्द हो जाने का कारण
            बन सकती है।
          </p>
          <p>बोनस का दुरुपयोग</p>
          <p>
            यदि कंपनी कोई ग्राहक बोनस का दुरुपयोग, अनुचित खेल, और/या विशेषज्ञीय
            रूप से दुरुपयोग योजनाओं में भाग लेने के संदेही होती है, तो कंपनी को
            ऐसे ग्राहकों के लिए विशेष जुआ शर्तें लगाने, बोनस रद्द करने, बिना
            किसी स्पष्टीकरण के।
            <br />
            इस बोनस प्रस्ताव का दुरुपयोग करने से बोनस और सभी बोनस जीत को रद्द कर
            दिया जाएगा, या खाते को बंद कर दिया जा सकता है।
          </p>
          <p>सामान्य शर्तें</p>
          <p>
            कंपनी को अनुबंध की शर्तें संशोधित, अनुरोध को रद्द करने या नवीकरण
            करने, या किसी भी समय पूर्व-सूचना के बिना भागीदारी की अनुमति न देने
            का अधिकार है।
            <br />
            बोनस को समझा जाएगा केवल जब सभी निर्धारित राशि के लिए सभी दांव ठीक से
            समाप्त हो जाएंगे।
            <br />
            बोनस प्रणाली का दुरुपयोग करने वाले खिलाड़ियों के बोनस और जीत से
            वंचित किए जा सकते हैं।
            <br />
            10% तक ₹2,500 का जमा बोनस दावा करने से, आप स्वीकार करते हैं कि आपने
            इन शर्तों और नियमों को पढ़ा, समझा और स्वीकार किया है। किसी विवाद की
            स्थिति में, हमारा निर्णय अंतिम और बाध्यकारी होगा।
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
