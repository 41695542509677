import { Button } from "bootstrap";
import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MdArrowBackIosNew,
  MdKeyboardArrowLeft,
  MdOutlineSubject,
} from "react-icons/md";
import { HiOutlineRefresh } from "react-icons/hi";
import MenuSidebar from "./MenuSidebar";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import loginImg from "../assets/images/login-icon.png";
import signUp from "../assets/images/sign-up.png";
import CurrentNews from "./CurrentNews";
const Header = () => {
  let {
    user,
    showSidebar,
    handleShowSidebar,
    userCoins,
    amounutRefresh,
    refreshAmountLoader,
  } = useContext(AuthContext);
  const navigte = useNavigate();
  const location = useLocation();
  const { messagelist, setAnnouncement } = useContext(AuthContext);

  const [logo, setLogo] = useState("../../assets/images/Logo_Transparent.png");

  useEffect(() => {
    // Get the domain name
    const domainName = window.location.hostname;
    // Dynamically set the logo based on the domain
    if (domainName.includes("anybett.live") || domainName.includes("www.anybett.live")) {
      setLogo("../../assets/images/anybett.png"); // Update with actual logo path
    } else if (domainName.includes("aaexch.live") || domainName.includes("www.aaexch.live")) {
      setLogo("../../assets/images/aaexch.png"); // Update with actual logo path
    } else{
      setLogo("../../assets/images/Logo_Transparent.png"); // Default logo
    }
  }, []);

  return (
    <>
      {/* {user && (
        <CurrentNews message={messagelist} setAnnouncement={setAnnouncement} />
      )} */}
      <div
        id="header"
        className={
          user
            ? "header bg-yellow d-flex justify-content-between before-login"
            : "header bg-yellow d-flex justify-content-between"
        }
      >
        <div className="logo-sec d-flex align-items-center">
          {!isEmpty(user) &&
          location?.pathname?.split("/")[1] !== "full-market" ? (
            <button
              className="bg-transparent border-0"
              onClick={() => handleShowSidebar()}
            >
              <MdOutlineSubject className="fs-4" />
            </button>
          ) : location?.pathname === "/" ? (
            ""
          ) : (
            <button
              className="bg-transparent border-0"
              onClick={() => navigte(-1)}
            >
              <MdArrowBackIosNew className="fs-4" />
            </button>
          )}

          <Link to="/" className={!user && "logo-before-user"}>
            <img src={logo}  alt="" />
          </Link>
        </div>

        {!isEmpty(user) ? (
          <div className="d-flex align-items-center after-login">
            {refreshAmountLoader ? (
              <div class="loader-outer">
                <span
                  class="loader-inner-icon"
                  style={{ animationDelay: "0s" }}
                ></span>
                <span
                  class="loader-inner-icon"
                  style={{ animationDelay: ".1s" }}
                ></span>
                <span
                  class="loader-inner-icon"
                  style={{ animationDelay: ".2s" }}
                ></span>
                <span
                  class="loader-inner-icon"
                  style={{ animationDelay: ".3s" }}
                ></span>
                <span
                  class="loader-inner-icon"
                  style={{ animationDelay: ".4s" }}
                ></span>
                <span
                  class="loader-inner-icon"
                  style={{ animationDelay: ".5s" }}
                ></span>
                <span
                  class="loader-inner-icon"
                  style={{ animationDelay: ".6s" }}
                ></span>
                <span
                  class="loader-inner-icon"
                  style={{ animationDelay: ".7s" }}
                ></span>
              </div>
            ) : (
              <div className="header-bg-righter">
                <span className="text-white">{user?.user?.username}</span>
                <div className="d-flex align-items-center text-white">
                  <strong className="header-font-size">
                    INR <span>{userCoins?.balance?.toFixed()}</span>
                  </strong>
                  <strong
                    className="header-font-size text-danger"
                    style={{ fontWeight: "600" }}
                  >
                    Exp{" "}
                    <span className="text-danger" style={{ fontWeight: "600" }}>
                      ({userCoins?.exp?.toFixed()})
                    </span>
                  </strong>
                </div>
              </div>
            )}
            <div onClick={amounutRefresh}>
              <HiOutlineRefresh
                size={20}
                className={refreshAmountLoader ? "refresh-icon" : ""}
              />
            </div>
          </div>
        ) : (
          <div className="social-header">
            <Link to="/register" className="signup">
              <figure>
                <img src={signUp} alt="" />
              </figure>
              Register
            </Link>
            <Link to="/login" className="login">
              <figure>
                <img src={loginImg} alt="" />
              </figure>
              Login
            </Link>
          </div>
        )}
      </div>

      {showSidebar && (
        <MenuSidebar cancelMenu={handleShowSidebar} showSidebar={showSidebar} />
      )}

      {showSidebar && (
        <div className="overlay" onClick={() => handleShowSidebar()}></div>
      )}
    </>
  );
};

export default Header;
